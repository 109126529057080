import React, { useEffect, useState } from "react";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import ExclamationIcon from "../icons/ExclamationIcon";
import InfoIcon from "../icons/InfoIcon";
import Bar from "./Bar";
import Close from "./Close";
import Icon from "./Icon";
import Span from "./Span";
import TextContent from "./TextContent";
import Title from "./Title";

function Notification({
  dispatch,
  icon,
  iconColor,
  id,
  message,
  title,
  type = "info",
  position = "topR",
  ...props
}) {
  const [isClosing, setIsClosing] = useState(false);
  const [intervalId, setIntervalId] = useState();
  const [barWidth, setBarWidth] = useState(0);

  const notificationWidth = 320;

  const startTimer = () => {
    if (isClosing) return;
    const idInt = setInterval(() => {
      setBarWidth((prev) => {
        if (prev < notificationWidth) return prev + 1;

        clearInterval(idInt);
        return prev;
      });
    }, 20);

    setIntervalId(idInt);
  };

  const pauseTimer = () => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    if (isClosing) return;
    if (barWidth === notificationWidth) closeNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barWidth, isClosing]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => startTimer(), []);

  const closeNotification = () => {
    pauseTimer();
    setIsClosing(true);
    setTimeout(() => {
      dispatch({
        type: "remove_notification",
        id,
      });
    }, 400);
  };

  return (
    <div
      id={id}
      className={`relative z-[9999]  top-0 border box-border outline-none p-4 bg-white rounded-2xl shadow-xl shadow-gray-200 flex m-4 w-80 overflow-hidden
      ${isClosing ? "animate-animate-out" : "animate-animate-in"}
      ${
        type === "success"
          ? "text-green-600"
          : type === "warning"
          ? "text-yellow-500"
          : type === "info"
          ? "text-blue-500"
          : "text-red-500"
      }`}
      onMouseEnter={pauseTimer}
      onMouseLeave={startTimer}
      {...props}
    >
      <Icon>
        {type === "success" ? (
          <CheckmarkIcon size={24} />
        ) : type === "info" ? (
          <InfoIcon size={24} />
        ) : (
          <ExclamationIcon size={24} />
        )}
      </Icon>
      <TextContent>
        <Title>{title}</Title>
        <Close onClick={closeNotification} />
        <Span>{message}</Span>
      </TextContent>
      <Bar width={barWidth} />
    </div>
  );
}

export default Notification;
