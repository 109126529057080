import "../styles/globals.css";
import React, { useRef, useEffect, memo } from "react";
import { MoralisProvider } from "react-moralis";
import { useRouter } from "next/router";
import Layout from "../components/Layout";
import NotificationProvider from "../context/NotificationContext";
import GoogleAnalytics from "../components/GoogleAnalytics";
import Head from "next/head";

//https://stackoverflow.com/questions/59124463/nextjs-how-to-not-unmount-previous-page-when-going-to-next-page-to-keep-state
const ROUTES_TO_RETAIN = ["/search"];

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const getLayout = Component.getLayout || ((page) => <Layout>{page} </Layout>);

  const retainedComponents = useRef({});

  const isRetainableRoute = ROUTES_TO_RETAIN.includes(router.asPath);

  // Add Component to retainedComponents if we haven't got it already
  if (isRetainableRoute && !retainedComponents.current[router.asPath]) {
    const MemoComponent = memo(Component);
    retainedComponents.current[router.asPath] = {
      component: <MemoComponent {...pageProps} />,
      scrollPos: 0,
    };
  }

  // Save the scroll position of current page before leaving
  const handleRouteChangeStart = (url) => {
    if (isRetainableRoute) {
      retainedComponents.current[router.asPath].scrollPos = window.scrollY;
    }
  };

  // Save scroll position - requires an up-to-date router.asPath
  useEffect(() => {
    router.events.on("routeChangeStart", handleRouteChangeStart);
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  // Scroll to the saved position when we load a retained component
  useEffect(() => {
    if (isRetainableRoute) {
      window.scrollTo(0, retainedComponents.current[router.asPath].scrollPos);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Component, pageProps]);

  const logo = "https://www.collats.com/collats_logo.png";
  const url = "https://www.collats.com";
  const ogTitle = "Collats - Add value to your NFTs now.";
  const ogDescription =
    "Collats is a new decentralized platform that provides NFTs the ability to hold Collateral value.";

  return (
    <>
      <GoogleAnalytics
        measurementId={process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}
      />
      <Head>
        <title>{ogTitle}</title>
        <meta name="description" content={ogDescription} />
        <meta
          name="keywords"
          content="NFT, Crypto Assets, Collats, Collateral Value, Stablecoin, Stable Coin"
        ></meta>
        <link rel="icon" href="/collats_logo.png" />

        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={ogTitle} />
        <meta property="og:image" content={logo} />
        <meta property="og:description" content={ogDescription} />

        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:site" content="@CollatsToken" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={logo} />
        <meta name="twitter:description" content={ogDescription} />
      </Head>

      <MoralisProvider
        appId={process.env.NEXT_PUBLIC_MORALIS_APP_ID}
        serverUrl={process.env.NEXT_PUBLIC_MORALIS_SERVER_URL}
      >
        <NotificationProvider>
          <div>
            <div style={{ display: isRetainableRoute ? "block" : "none" }}>
              {Object.entries(retainedComponents.current).map(([path, c]) => (
                <div
                  key={path}
                  style={{
                    display: router.asPath === path ? "block" : "none",
                  }}
                >
                  {c.component}
                </div>
              ))}
            </div>
            {!isRetainableRoute && getLayout(<Component {...pageProps} />)}
          </div>
        </NotificationProvider>
      </MoralisProvider>
    </>
  );
}

export default MyApp;
