import React from "react";
import CloseIcon from "../icons/CloseIcon";
import Icon from "./Icon";

function Close({ ...props }) {
  return (
    <div
      className="border-none box-border m-0 outline-none p-0 cursor-pointer absolute right-4 top-4 text-gray-500"
      {...props}
    >
      <Icon>
        <CloseIcon size={32} />
      </Icon>
    </div>
  );
}

export default Close;
