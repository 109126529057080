import React, { useEffect, useState } from "react";
import { useChain, useMoralis } from "react-moralis";
import { Dropdown } from "web3uikit";
import { getNetwork, networks } from "../helpers/networks";

function Networks() {
  //This should be my source of truth since is what the user is looking at.
  const [selected, setSelected] = useState(0);
  const { isAuthenticated } = useMoralis();
  const { switchNetwork, chainId } = useChain();

  const handleNetworkChange = async (selected) => {
    const network = networks[selected.id];
    if (!network.chainId) return;
    switchNetwork(network.chainId);
  };

  useEffect(() => {
    if (!isAuthenticated) setSelected(0);
    if (!chainId) return;

    const network = getNetwork(chainId);
    if (network) {
      setSelected(network.index);
    } else {
      setSelected(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return (
    <div>
      <Dropdown
        width="150px"
        selectedState={selected}
        showSelected
        defaultOptionIndex={selected}
        onChange={handleNetworkChange}
        onComplete={function noRefCheck() {}}
        options={[
          {
            id: "none",
            label: <div className="pr-4">Network</div>,
          },
          {
            id: "eth",
            label: <div className="pr-4">Ethereum</div>,
            prefix: <img src="/images/eth.svg" className="pr-2" />,
          },
          {
            id: "bsc",
            label: <div className="pr-4">BNB</div>,
            prefix: <img src="/images/bsc.svg" className="pr-2" />,
          },
          {
            id: "polygon",
            label: <div className="pr-4">Polygon</div>,
            prefix: <img src="/images/polygon.svg" className="pr-2" />,
          },
        ]}
      />
    </div>
  );
}

export default Networks;
