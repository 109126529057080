export const networks = {
  none: {
    index: 0,
    chainId: undefined,
    chainName: "Network",
  },
  eth: {
    index: 1,
    chainId: `0x${Number(1).toString(16)}`,
    chainName: "Ethereum Mainnet",
    shortName: "Ethereum",
    network: "eth",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      logo: "/images/eth.svg",
    },
    wrapped: {
      name: "WETH",
      symbol: "WETH",
      decimals: 18,
      address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      logo: "/images/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.svg",
    },
    rpcUrls: [
      `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_API_KEY}`,
    ],
    blockExplorerUrls: ["https://etherscan.io"],
  },
  bsc: {
    index: 2,
    chainId: `0x${Number(56).toString(16)}`,
    chainName: "Binance Smart Chain Mainnet",
    shortName: "BSC",
    network: "bsc",
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      logo: "/images/bsc.svg",
    },
    wrapped: {
      name: "WBNB",
      symbol: "WBNB",
      decimals: 18,
      address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
      logo: "/images/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.svg",
    },
    rpcUrls: [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org",
    ],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  polygon: {
    index: 3,
    chainId: `0x${Number(137).toString(16)}`,
    chainName: "Polygon Mainnet",
    shortName: "Polygon",
    network: "polygon",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
      address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      logo: "/images/polygon.svg",
    },
    wrapped: {
      name: "WMATIC",
      symbol: "WMATIC",
      decimals: 18,
      address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
      logo: "/images/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270.svg",
    },

    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com"],
  },
};

export function getNetwork(chainId) {
  try {
    for (let network in networks) {
      if (networks[network].chainId === chainId) return networks[network];
    }
  } catch (error) {
    console.log(error);
  }
}

export function getNetworkByName(c) {
  try {
    if (!c) return;
    let chain =
      c.toLowerCase() === "ethereum"
        ? "eth"
        : c.toLowerCase() === "matic"
        ? "polygon"
        : c.toLowerCase();

    for (let network in networks) {
      if (networks[network].network === chain) return networks[network];
    }
  } catch (error) {
    console.log(error);
  }
}

export function getNetworkLogo(c) {
  try {
    if (!c) return;
    let chain =
      c.toLowerCase() === "ethereum"
        ? "eth"
        : c.toLowerCase() === "matic"
        ? "polygon"
        : c.toLowerCase();

    for (let network in networks) {
      if (networks[network].network === chain)
        return networks[network].nativeCurrency.logo;
    }
  } catch (error) {
    console.log(error);
  }
}

export function getNetworkShortName(c) {
  try {
    if (!c) return;
    let chain =
      c.toLowerCase() === "ethereum"
        ? "eth"
        : c.toLowerCase() === "matic"
        ? "polygon"
        : c.toLowerCase();

    for (let network in networks) {
      if (networks[network].network === chain)
        return networks[network].shortName;
    }
  } catch (error) {
    console.log(error);
  }
}

export const networkConfigs = {
  "0x1": {
    currencySymbol: "ETH",
    network: "eth",
    blockExplorerUrl: "https://etherscan.io/",
    wrapped: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  },
  "0x3": {
    currencySymbol: "ETH",
    blockExplorerUrl: "https://ropsten.etherscan.io/",
  },
  "0x4": {
    currencySymbol: "ETH",
    blockExplorerUrl: "https://rinkeby.etherscan.io/",
  },
  "0x2a": {
    currencySymbol: "ETH",
    blockExplorerUrl: "https://kovan.etherscan.io/",
  },
  "0x5": {
    currencySymbol: "ETH",
    blockExplorerUrl: "https://goerli.etherscan.io/",
  },
  "0x539": {
    chainName: "Local Chain",
    currencyName: "ETH",
    currencySymbol: "ETH",
    rpcUrl: "http://127.0.0.1:7545",
  },
  "0xa86a": {
    chainId: 43114,
    chainName: "Avalanche Mainnet",
    currencyName: "AVAX",
    currencySymbol: "AVAX",
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    blockExplorerUrl: "https://cchain.explorer.avax.network/",
  },
  "0x38": {
    chainId: 56,
    chainName: "Smart Chain",
    network: "bsc",
    currencyName: "BNB",
    currencySymbol: "BNB",
    rpcUrl: "https://bsc-dataseed.binance.org/",
    blockExplorerUrl: "https://bscscan.com/",
    wrapped: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
  },
  "0x61": {
    chainId: 97,
    chainName: "Smart Chain - Testnet",
    currencyName: "BNB",
    currencySymbol: "BNB",
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    blockExplorerUrl: "https://testnet.bscscan.com/",
  },
  "0x89": {
    chainId: 137,
    chainName: "Polygon Mainnet",
    network: "polygon",
    currencyName: "MATIC",
    currencySymbol: "MATIC",
    rpcUrl: "https://rpc-mainnet.maticvigil.com/",
    blockExplorerUrl: "https://explorer-mainnet.maticvigil.com/",
    wrapped: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
  },
  "0x13881": {
    chainId: 80001,
    chainName: "Mumbai",
    network: "mumbai",
    currencyName: "MATIC",
    currencySymbol: "MATIC",
    rpcUrl: "https://rpc-mumbai.matic.today/",
    blockExplorerUrl: "https://mumbai.polygonscan.com/",
  },
};

export const getNativeByChain = (chain) =>
  networkConfigs[chain]?.currencySymbol || "NATIVE";

export const getChainById = (chain) => networkConfigs[chain]?.chainId || null;

export const getChainNetworkById = (chain) =>
  networkConfigs[chain]?.network || null;

export const getExplorer = (chain) => networkConfigs[chain]?.blockExplorerUrl;

export const getWrappedNative = (chain) =>
  networkConfigs[chain]?.wrapped || null;
