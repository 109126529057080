import React from "react";

function Span({ children }) {
  return (
    <span className="text-base text-gray-500 inline-block break-words mr-1">
      {children}
    </span>
  );
}

export default Span;
