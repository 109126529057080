import React from "react";

function NotificationContainer({ position, children, ...props }) {
  return (
    <div className="fixed right-0 top-0 z-30" {...props}>
      {children}
    </div>
  );
}

export default NotificationContainer;
