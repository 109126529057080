import React from "react";

function Icon({ children }) {
  return (
    <div className="border-none box-border m-0 outline-none p-0 flex h-6 w-6 items-center justify-center rounded-full">
      {children}
    </div>
  );
}

export default Icon;
