import React from "react";

function Bar({ children, width }) {
  return (
    <div
      style={{ width: `${width}px` }}
      className="bg-blue-100 bottom-0 h-2 left-0 absolute rounded-tr-2xl rounded-br-2xl"
    >
      {children}
    </div>
  );
}

export default Bar;
