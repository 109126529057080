import React from "react";
import Image from "next/image";
import Link from "next/link";

function LogoAndTitle({ className, width, height }) {
  return (
    <div className={className}>
      <Link href="/">
        <a>
          <Image
            src="/collats_logo_title.svg"
            alt="Collats Logo"
            width={width}
            height={height}
          />
        </a>
      </Link>
    </div>
  );
}

export default LogoAndTitle;
