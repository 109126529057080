import React from "react";

function Title({ children }) {
  return (
    <h5 className="text-base inline-block font-semibold m-0 break-words mr-16">
      {children}
    </h5>
  );
}

export default Title;
