import Link from "next/link";
import React from "react";
import Logo from "../components/Logo";
import LogoAndTitle from "./LogoAndTitle";
import { AiOutlineSearch } from "react-icons/ai";
import { useRouter } from "next/router";
import { ConnectButton } from "web3uikit";
import Networks from "./Networks";
import { useMoralis } from "react-moralis";

const navigationItems = [
  { name: "Home", href: "/", current: true },
  { name: "Collection", href: "/collection", current: false },
  { name: "Swap", href: "/swap", current: false },
];

const style = {
  wrapper: "bg-[#ffffff] w-screen lg:px-[1.2rem] py-[0.8rem] flex",
  logoContainer: `flex items-center cursor-pointer`,
  searchBar:
    "hidden lg:flex lg:flex-1 mx-[0.8rem] w-max-[520px] items-center bg-gray-100 rounded-[0.8rem] hover:bg-gray-50 ",
  searchIcon: `text-[#8a939b] mx-3 font-bold text-lg`,
  searchInput: `h-[2.6rem] w-full border-0 bg-transparent outline-0 ring-0 px-2 pl-0 text-[#e6e8eb] placeholder:text-[#8a939b]`,
  headerItemsLarge: "hidden lg:flex items-center justify-end",
  headerItemsSmall: "flex lg:hidden items-center justify-center pb-4",
  headerItem: `px-4 font-bold text-gray-500 hover:text-black cursor-pointer`,
  headerIcon: `text-[#8a939b] text-3xl font-black px-4 hover:text-black cursor-pointer`,
};

function Header() {
  const { isAuthenticated } = useMoralis();
  const router = useRouter();

  return (
    <div>
      <header className="shadow-sm">
        <div className={style.wrapper}>
          <Link href="/">
            <div className={style.logoContainer}>
              <LogoAndTitle
                className="hidden lg:flex"
                width={150}
                height={50}
              />
            </div>
          </Link>

          <Link href="/search">
            {/* <a> */}
            <div
              className={style.searchBar}
              onClick={() => {
                // router.push("/?search=nft");
              }}
            >
              <div className={style.searchIcon}>
                <AiOutlineSearch />
              </div>
              <input
                disabled
                className={style.searchInput}
                placeholder="Search items, collections, and contracts"
              />
            </div>
            {/* </a> */}
          </Link>

          <div className={style.headerItemsLarge}>
            {navigationItems.map((item) => {
              const isCurrent = router.asPath === item.href;

              return (
                <Link key={item.name} href={item.href}>
                  <a>
                    <div
                      className={
                        isCurrent
                          ? "px-4 font-light text-black hover:text-black cursor-pointer"
                          : "px-4 font-light text-gray-500 hover:text-black cursor-pointer"
                      }
                    >
                      {item.name}
                    </div>
                  </a>
                </Link>
              );
            })}
          </div>
          <div className="z-10 w-screen lg:w-auto flex  lg:flex-none items-center justify-center  ">
            {isAuthenticated && (
              <div className="scale-75 md:scale-100">
                <Networks />
              </div>
            )}
            <ConnectButton signingMessage="collats.com" />
          </div>
        </div>

        <div className={style.headerItemsSmall}>
          <Logo className="lg:hidden" width={48} height={48} />
          {navigationItems.map((item) => {
            const isCurrent = router.asPath === item.href;
            return (
              <Link key={item.name} href={item.href}>
                <a>
                  <div
                    className={
                      isCurrent
                        ? "px-4 font-light text-black hover:text-black cursor-pointer"
                        : "px-4 font-light text-gray-500 hover:text-black cursor-pointer"
                    }
                  >
                    {item.name}
                  </div>
                </a>
              </Link>
            );
          })}

          <Link href="/search">
            <a>
              <AiOutlineSearch
                className="  lg:hidden hover:bg-gray-50"
                size={24}
              />
            </a>
          </Link>
        </div>
      </header>
    </div>
  );
}

export default Header;
